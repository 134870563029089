.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  
  &.no-header {
      grid-template-columns: 1fr 2fr;
      grid-template-areas: 'aside content'
      'aside footer';
  }
  
  @media (min-width: get-media(sm)) {
      display: grid;
      grid-template-areas: 
                           'aside content'
                           ;
      gap: 0 calc(var(--margin) * 4);
      grid-template-columns: 2fr 15fr;
      //padding: calc(var(--padding) * 2);
      grid-template-rows: 0fr auto auto;
  }
  
}

.header + .layout, .header + .confirmation {
  margin-top: calc(var(--margin) * 4);
}


.aside {
  grid-area: aside;
  order: 2;

  .card {
      padding: 0;
  }

  .section-title, .section-body {
      margin: calc(var(--margin) /4) 0;
  }
  
  .section-title, .item {
      font-size: .96em;
  }

  @media (min-width: get-media(sm)) {
      margin-left: initial;
      margin-right: initial;

      .card {
          padding: calc(var(--padding) * 0.9);
      }

  }
}

.block{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  order: 3;
  flex: 1 0 auto;
  gap: 35px;
  margin-top: 18px;
}

.line{
  overflow: hidden;
  font-weight: 500;
  @media screen and (max-width: 600px) {
    font-weight: 500;   
  }
}

.line:after{
  content:"";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid var(--cinza);
}

.link-button{
  border: 1px solid var(--cor-evento);
  border-radius: 5px;
  color: var(--cor-evento);
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px 0px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  svg{
    width: 20px;
    height: 20px;
  }
}

.conteudo{
  margin: 28px auto 35px auto;
  max-width: 1400px;
  padding: 0px;
  min-height: 520px;
  display: block;
  h2{
    color: var(--text-default);
  }
  h1{
    color: var(--text-default);
    font-size: 24px;
  }
  @media screen and (max-width: 1500px) {
    margin: 28px 3% 48px 3%;
  }
  &.buscar{
    margin-top: 100px;
    .lista{
      justify-content: flex-start;
    }
    @media screen and (max-width:600px) {
      margin-top: 150px;
    }
  }
}

.aviso{
  text-align: center;
  max-width: 600px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  img{
    width: 250px;
  }
  p{
    color: var(--texto);
  }
  .btn-outline{
    font-size: 0.9em;
    border-radius: 5px;
  }
}


.card{
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  border:1px solid var(--card-border);
  max-width: 250px;
  min-height: 240px;
  align-content: flex-start;

  .imagem {
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
    }
  }

  .info{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 65%;
    .nome{
      color: var(--azul);
      font-weight: 600;
      font-size: 18px;
      @media screen and (min-width: 600px) {
        letter-spacing: 0.01em;
      }
    }
    .cidade{
      color: var(--link);
      font-size: 14px;
    }
    .lugar{
      font-size: 13px;
      color: var(--cinza-forte);
      @media screen and (min-width: 600px) {
        display: none;
      }
    }
    &.home{
      @media screen and (max-width: 600px) {
        .cidade{
          padding: 2px 0px;
          font-weight: 500;
        } 
      }
    }
  }
  
}

.card:hover{
  transform: scale(1.05);
  box-shadow: 0 21px 32px -16px rgba(46,55,77,0.15);
  text-decoration: none;
}

.eventos{
  &.ultimos{
    .lista{
      justify-content: flex-start;
      gap: 25px;
      row-gap: 12px;
      @media screen and (min-width: 1500px) {
        gap: 35px;
      }
      @media screen and (max-width: 1400px) {
        gap: 18px;
      }
    }
  }
}

.react-loading-skeleton{
  border-radius: 20px 20px 0 0;
}

@media screen and (max-width: 600px) {
  .hidden-lg{
    display: block;
  }
  .hidden-sm{
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .hidden-lg{
    display: none;
  }
  .hidden-sm{
    display: block;
  }
}

.box-aviso{
  padding: 10px;
  background-color: #f3a02b;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: white;
  border-radius: 8px;
  margin: 20px 0px -34px;
  display: flex;
  align-items: center;
  gap: 10px;
  svg{
    width: 34px;
    height: 34px;
  }
  @media screen and (max-width: 600px) {
    margin: -13px 3% -11px;
    svg{
      display: none;
    }
  }
}