.overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: white;
}

.nav-search{
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  .modal-busca{
    display: flex;
    flex-direction: row;
    height: 55px;
    align-items: center;
  }
  .voltar{
    padding: 4px 5px 0px 15px;
  }
  #input-mobile{
    border: none;
    flex: 1 1 auto;
    font-size: 17px;
    // font-family: 'Rubik';
  }
  #input-mobile:focus-visible{
    border: none;
  }
  #input-mobile::placeholder{
    color: #8794A0;
  }
  button{
    border: none;
    background-color: transparent;
    padding: 5px 15px 0 0;
    color: #3f3f3f;
  }
  textarea:focus, input:focus{
    outline: none;
  }
}

// aparece somente no mobile
.mais-procurados{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 25px 5%;
  .titulo{
    font-size: 20px;
    color: #B4B4B4;
    font-weight: 500;
    letter-spacing: 0.015em;
  }
  .listagem{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #181818;
      .texto{
        display: flex;
        flex-direction: column;
        .nome{
          font-size: 18px;
        }
        .categoria{
          font-size: 12px;
          color: #B4B4B4;
          letter-spacing: 0.01em;
        }
      }
      svg{
        width: 20px;
        height: 29px;
      }
    }
  }
}

.modal-busca{
  .voltar svg, button svg{
    width: 20px;
    height: 20px;
  }

}

//RESULTADOS DA BUSCA

@media screen and (max-width: 600px){
  .resultados-busca{
    display: none;
  }
}

@media screen and (min-width: 601px){
  .nav-search{
    display: none;
  }
}