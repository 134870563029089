.chat{
  width: 55px;
  height: 54px;
  border: 2px solid var(--text-primary);
  padding: 0px;
  position: fixed;
  z-index: 999;
  bottom: 16px;
  left: 16px;
  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, .5);
  cursor: pointer;
  em{
    display: inline-block;
    overflow: hidden;
    text-align: center;
    position: relative;
    height: 35px;
    width: 35px;
    img{
      width: 100%;
    }
  }
  &-mark{
    border-radius: 20px;
    bottom: 55px;
    left: 55px;
    border: 2px solid var(--text-primary);
    width: 16px;
    color: #fff;
    height: 16px;
    background-color: var(--text-primary);
    position: fixed;
    text-align: center;
    line-height: 16px;
    z-index: 999;
    font-size: 10px;
    display: none;
    @media screen and (max-width: 600px) {
      &.top{
        bottom: 138px;
      }
    }
  }
  &.chat-close{
    background-color: var(--text-primary);
    width: 50px;
    height: 50px;
    z-index: 999 !important;
    img{
      width: 20px;
    }
  }
  &-text{
    &.baladapp{
      .chat-mark{
        display: block;    
        bottom: 60px;
        left: 60px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    &.top{
      bottom: 88px;
    }
  }

  &.cruzeiro, &.bemingressos{
    background-color: var(--text-primary);
  }
  &.baladapp{
    background-color: #FFF;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    em{
      width: 54px;
      height: 57px;
      border-radius: 50%;
    }
  }
}