.vitrine{
  &.navbar{
    gap: 50%; 
    justify-content: space-around;   
    .evento{
      display: flex;
      align-items: center;
      padding: 5px 0px;
      gap: 10px;
      color: var(--texto);
      img{
        width: 80px;
        border-radius: 5px;
      }
      .info{
        h3{
          font-size: 1.3em;
        }
        span{
          color: var(--link);
        }
      }

    }
    .close{
      svg{
        width: 25px;
        height: 25px;
      }
    }
    @media screen and (max-width:600px) {
      gap: 10px;
      height: auto;
      .evento{
        padding-left: 20px;
        .info h3{
          font-size: 1.2em;
        }
      }
      .close{
        padding-right: 20px;
      }
    }
  }
  &#comprar-flutuante{
    color: #667085;
    text-align: left;
    .quantidade{
      b{
        font-size: 1.5em;
      }
    }
    .valor{
      b{
        color: #3F3F3F;
        font-size: 1.75em;
      }
    }

    .btn-primaria{
      padding: 20px 75px;
    }
    @media screen and (max-width:600px) {
      .btn-primaria{
        padding: 20px 20px;
      }
    }

  }
  .ingressos{
    margin-top: 50px;
    max-width: 1200px;
    margin: 50px auto 150px auto;
    @media screen and (max-width: 600px) {
      margin-top: 125px;
    }
  }
}


.abertura-de-vendas{
  text-align: center;
  max-width: 900px;
  color: #3F3F3F;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 125px;
  .contador{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .cronometro{
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      li{
        display: inline-block;
        font-size: 1.2em;
        list-style-type: none;
        padding: 0em 1em 0.2em 1em;
        @media screen and (max-width: 600px) {
          padding: 0em 0.5em 0.2em 0.5em;
        }
        p{
          &:first-of-type{
            display: block;
            font-size: 2.5em;
            line-height: 48px;
            font-weight: bold;
            color: var(--primaria);
          }
          &:last-of-type{
            
          }
        }
      }
      .colon{
        font-size: 2em;
        padding: 0 0 32px 0;
      }
    }
  }
  .notificacao{
    display: flex;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    img{
      width: 250px;
    }
    h3{
      font-size: 1.75em;
    }
  }
  @media screen and (max-width:600px){
    margin: 40px;
    padding-bottom: 50px;
  }
}