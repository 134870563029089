
// HEADER
.navbar{
  display: flex;
  z-index: var(--z-nav);
  padding: 6px 0px;
  //position: fixed;
  width: 100%;
  align-items: center;

  .logo{
    padding: 0 20px;
    a img{
      color: var(--header-logo);
      width: 164px;
    }
    a svg{
      color: var(--header-logo);
      height: 30px;
      width: 160px;
    }
  }

  .busca{
      display: flex;
      order: 2;
      flex: 2 1 auto;
      margin: 5px 12px;
      max-width: 400px;
      z-index: 1;
      .campo{
        border: var(--border-primary);
        position: relative;
        border-radius: 8px;
        padding: 8px;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        flex: 2 1 0%;
        background-color: var(--header-search-background);
        z-index: 20;

        svg{
          width: 20px;
          order: 2;
          cursor: pointer;
          color: var(--header-text-title);
        }
        input{
          order: 1;
          border: none;
          width: 100%;
          padding: 0px 8px;
          font-size: 16px;
          outline: none;
          box-sizing: border-box;
          color: var(--header-text-default);
          background-color: var(--header-search-background);
          &::placeholder{
            color: var(--header-text-title);
          }
        }
      }
  }
  
  .localizacao{
      display: flex;
      order: 3;
      flex: 1 0 auto;
      justify-content: center;
      cursor: pointer;
      svg{
        width: 20px;
        height: 20px;
        color: var(--primaria);
      }
      a{
        display: flex;
        align-items: center;
        gap: 6px;
      }
      p{
        color: var(--azul-opacidade);
        letter-spacing: 0.02em;
      }
      .alerta{
        position: absolute;
        width: 200px;
        background-color: white;
        border: 1px solid var(--color-border);
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ballon{
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 2;
          padding: 12px 0 14px 0;
          gap: 3.5px;
          p{
            color: var(--azul);
            letter-spacing: -0.01em;
          }
          .cidade{
            font-weight: 600;
            font-size: 21px;
          }
          .continuar{
            border-radius: 10px;
            color: var(--white);
            background-color: var(--botao-azul);
            font-weight: 600;
            padding: 8px 21px;
            margin-top: 6px;
          } 
          .trocar-cidade{
            color: var(--cinza-forte);
            svg{
              color: var(--primaria);
            }
          }
          }

          &::after{
            content: '';
            height: 20px;
            width: 20px;
            background: var(--white);
            transform: rotate(45deg);
            position: absolute;
            left: 46%;
            top: -11px;
            border: 1px solid var(--color-border);
            border-color:  var(--color-border) transparent transparent var(--color-border);
          }
      }
  }

  .acesso{
    display: flex;
    order: 4;
    flex: 0 1 auto;
    flex-direction: row;
    text-transform: uppercase;
    margin: 5px 18px;
    width: auto;
    gap: 20px;
    align-items: center;
    @media screen and (max-width: 900px) {
      gap: 2px;
    }
    a{
      font-size: 12px;
      letter-spacing: 0.15em;
      font-weight: 500;
    }

    .login{
      color: var(--primaria);
      display: flex;
      align-items: center;
      svg{
        width: 25px;
        height: 16px;
      }
    }
    .cadastrar{
      color: var(--white);
      background-color: var(--primaria);
      border-radius: 5px;
      padding: 15px 15px;
      text-align: center;
    }
    .icon-mobile{
      svg{
        color: var(--primaria);
        width: 34px;
        height: 34px;
      }
    }
  }
  &.home{
    position: fixed;
    background-color: var( --header-background);
  }
}


//PAGINA DO EVENTO
#evento{
  justify-content: space-between;
}

//MOBILE
@media screen and (max-width: 600px) {
  .navbar{
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      justify-content: space-between;
      height: 60px;
      background-color: var(--white);
      margin-top: -1px;
      position: fixed;
      .logo{
        a{
          img{
            width: 170px;
          }
        }
      }
      .busca{
        display: none;
      }
      .localizacao{
        display: none;
      }
      .acesso{
        margin: 5px 10px;
        .login{
          display: none;
        }
        .cadastrar{
          display: none;
        }
      }
      &#evento{
        background-color: transparent;
        height: 270px;
        .logo{
          svg{
            color: var(--cor-evento-secundaria);
          }
        }
        .icon-mobile{
          svg{
            color: var(--cor-evento);
          }
        }
      }
  }
  .search-mobile{
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    top: 70px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    padding: 15px 0 13px 0;
    align-items: center;
    z-index: var(--z-header);
    background-color: var(--header-background);
    .localizacao{
      display: flex;
      margin-left: 12px;
      gap:10px;
      color: var(--primaria);
      font-weight: 600;
      svg{
        width: 20px;
        color: var(--primaria);
        height: 20px;
      }
      .trocar-cidade{
        color: var(--primaria);
      }
      p{
        color: var(--primaria);
      }
    }
    .busca{
      margin-right: 20px;
      svg{
        width: 20px;
        height: 20px;
        @media screen and (max-width:600px) {
          color: var(--primaria);
        }
      }
    }
  }
}


//DESKTOP
@media screen and (min-width:600px){
  .acesso{
    .icon-mobile{
      display: none;
    }
  }
  .search-mobile{
    display: none;
  }
  .scroll{
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  }
}
