.baladapp-modal-texto{
  font-family: 'Roboto';
  @media screen and (max-width: 600px) {
    padding: 20px;
    min-height: 100vh;
  }
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    h2{
      font-size: 1.5em;
      color: var(--text-dark);
      margin: 10px 0px;
    }
 
    svg{
      width: 30px;
      height: 30px;
      color: var(--text-dark);
    }

  }
  .body{
    display: flex;
    flex-direction: column;
    color: var(--text-dark);
    margin-top: 10px;
    h4{
      margin: 10px 0px;
    }
    a{
      color: var(--text-primary);
    }
  }
}

.modal-info{
  @media screen and (min-width: 600px) {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 50%;
    height: auto;
    margin: auto;
  }
}

.ReactModal__Overlay{
  z-index: 99999999;
  @media screen and (max-width: 600px) {
    overflow-y: auto;
    width: 100%;
    .ReactModal__Content{
      width: 100% !important;
      background-color: #fff !important;
      height: 100vh !important;
    }
  }
}