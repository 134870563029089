.info{
  border: 1px solid transparent;
  margin: 0 auto;
  width: 100%;
  color: var(--texto);
  .info-header{
    padding: 25px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: #ddd;
    border: 1px solid #ddd;
    border-bottom: none;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 8px;
    p{
      // display: flex;
      align-items: center;
      gap: 3px;
    }
    svg{
      width: 15px;
      height: 15px;
    }
    b{
      font-weight: 500;
    }
    a{
      color: var(--cor-evento);
    }
  }
  .info-body{
    padding: 25px 20px;
    font-size: .9em;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    p{
      line-height: 25px;
    }
    a{
      color: var(--cor-evento);
    }
  }
  &.location{
      .info-body{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
  }

  .subtitle{
      text-transform: uppercase;
      font-weight: 600;
      padding: 20px 0 5px 0;
      margin-bottom: 15px;
      font-size: 18px;
      letter-spacing: 0.025em;
      position: relative;
      &:after {
          display: block;
          content: '';
          width: 4%;
          height: 0.125em;
          background: var(--cor-evento);
          position: absolute;
          bottom: 0;
          left: 0;
      }
  }

  .card{
      max-width: 175px;
      min-height: 142px;
      justify-content: center;
      .imagem{
          margin-bottom: 0px;
          height: 125px;
          img{
              object-fit: cover;
          }
      }
      .artista{
          text-align: center;
          padding: 20px 0;
          color: var(--texto);
          font-weight: 600;
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 0.02em;
      }
  }

  .flex-info{
    display: flex;
    gap: 5px;
    margin-bottom: 12px;
    svg{
      width: 20px;
      height: 20px;
    }
  }

  .atracoes{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 44px 30px;
      margin: 25px auto;
      justify-items: center;
  
      @media screen and (max-width: 1250px) {
          grid-template-columns: repeat(4, 1fr);
          gap: 35px 55px;
      }
      @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 830px) {
          grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 550px) {
          grid-template-columns: repeat(1, 1fr);
      }
  }

  button{
    font-weight: 600;
    letter-spacing: 0.02em;
    border: 0.2em solid var(--cor-evento);
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 6px;
    font-family: 'Rubik';
    cursor: pointer;
    background-color: transparent;
    color: var(--cor-evento);
    svg{
        width: 20px;
        height: 20px;
    }
  }
}

#informacoes{
  .info-body{
    border-radius: 0px 0px 8px 8px;
    span{
      font-family: 'Rubik' !important;
    }
    img{
      max-width: 100%;
    }
  }
}

#encerrado{
  text-align: center;
  max-width: 70%;
  margin: 40px auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
  h3{
    color: #3f3f3f;
    font-size: 1.75em;
  }
}