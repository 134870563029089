aside{
  display: flex;
  flex-direction: column;
  gap: 15px;
  .evento-menu{
    display: flex;
    flex-direction: column;
    gap: 5px;
    a img{
      width: 225px;
      height: 225px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .side-menu{
    ul{
      list-style-type: none;
      color: var(--cinza-forte);
      text-transform: uppercase;
      font-weight: 500;
      padding-inline-start: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      letter-spacing: 0.025em;
      li.active{
        a{
          color: var(--cor-evento);
          &:before{
            content: "";
            position: absolute;
            background-color: var(--cor-evento);
            -webkit-mask-image: url('./img/list-style.svg');
            mask-image: url('./img/list-style.svg');
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            width: 40px;
            height: 40px;
            margin-top: -9px;
            float: left;
            left: -45px;
          } 
        }
        .sub-menu{
          a::before{
            content: none;
          }
        }
      }

      a{
        cursor: pointer;
        text-decoration: none;
        color: var(--cinza-forte);
      }
    }
  }
  .sub-menu{
    padding: 10px 0 5px 0;
    font-size: 13px;
  }
}
