@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../stylesheets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 600;
  src: url("../stylesheets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url("../stylesheets/fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url("../stylesheets/fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url("../stylesheets/fonts/Rubik-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url("../stylesheets/fonts/Rubik-Bold.ttf") format("truetype");
}
