#comprar-flutuante{
  display: flex;
  justify-content: space-evenly;
  background-color: #FFF;
  border-top: 1px solid #E7E7E7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;
  text-align:center;
  padding: 20px 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  z-index: 10;
  button{
    padding: 14px 50px;
  }
  &.esconder{
    height: 0px;
    opacity: 0;
    transition: all 200ms linear;

  }
  &.mostrar{
    height: 40px;
    opacity: 1;
    transition: all 200ms linear;
  }
  .texto{
    text-align: left;
  }
  .sessoes{
    span{
      font-size: 14px;
    }
  }
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    .sessoes{
      display: none;
    }
    .texto{
      width: 50%;
      p{
        font-size: 14px;
      }
    }
  }
  p{
    color: var(--texto);
    font-weight: 500;
    font-size: 19px;
  }
  a{
    padding: 20px 25px;
    font-size: .9em;
  }
  @media screen and (max-width: 600px) {
    width: 93%;
    span{
      font-size: .9em;
      width: 45%;
      text-align: left;
    }
    a{
      padding: 15px;
      // width: 35%;
    }
  }
  @media (min-width: 601px) and (max-width: 900px){
    p{
      font-size: 14px;
      max-width: 250px;
    }
  }
}