.artistas{
  display: flex;
  gap: 10px;
  // min-height: 600px;
  flex-wrap: wrap;
  &-card{
    border-radius: 20px;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    img{
      width: 200px;
      border-radius: 20px 20px 0 0;
      height: 150px;
      object-position: 0 2%;
      object-fit: cover;

    }
    &:hover{
      transform: scale(1.01);
    }

  }
  &-info{
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    letter-spacing: 0.15px;
    span{
      text-align: center;
      text-transform: uppercase;
      &:first-of-type{
        color: var(--texto);
        font-weight: bold;
      }
      &:last-of-type{
        color: var(--cinza-forte);
      }
    }
  }
}

.layout.artista{
  margin: 0 auto;
  min-height: 600px;
  .side-menu{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--azul);
    .titulo{
      h1{
        font-weight: 500;
        font-size: 26px;
      }
    }
    @media screen and (max-width: 600px) {
      align-items: center;
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: -125px;
  }
  .lista{
    justify-content: flex-start;
  }
}


.redes-sociais{
  display: flex;
  flex-direction: row;
  gap: 8px;
  a{
    color: var(--cor-evento);
    display: flex;
    align-items: center;
    background-color: rgba(153, 153, 153, 0.2);
    padding: 6px;
    border-radius: 50px;
    svg{
      width: 20px;
      height: 20px;
    }
    @media screen and (max-width: 600px) {
      padding: 10px;
      svg{
        width: 25px;
        height: 25px;
      }
    }
  }
}

.spotify{
  background-color: rgba(153, 153, 153, 0.2);
  display: flex;
  padding: 10px 10px;
  color: var(--azul);
  border-radius: 12px;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  gap: 9px;
  align-items: center;
  svg{
    color: var(--azul);
    &:first-of-type{
      width: 23px;
      height: 23px;
    }
    &:last-of-type{
      width: 10px;
      height: 10px;
    }
  }
  span{
    font-weight: 500;
  }
}