.destaques{
  .carrosel{
    display: flex;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 103%;
    
    a {
      position:relative;
      min-width:155px;
      height:200px;
      svg{
        fill:none;
        stroke: var(--primaria);
        stroke-linecap: round;
        stroke-width:1;
        stroke-dasharray: 1;
        stroke-dashoffset: 0;
        animation: stroke-draw 6s ease-out infinite alternate; 
        // animation: stroke-draw 6s cubic-bezier(0.77, 0, 0.175, 1) infinite alternate; 
      }
      .background img{
        position:absolute;
        left:50%;
        top:49%;
        transform:translate(-50%, -50%);
        width:140px;
        border-radius:50%;
        filter: brightness(0.5);
      }
      .info{
        position: absolute;
        display: flex;
        flex-direction: column;
        text-align: center;
        z-index: 2;
        color: #fff;
        font-weight: bold;
        align-items: center;
        text-transform: uppercase;
        top: 28%;
        font-size: 14px;
        gap: 5px;
        img{
          height: 50px;
          width: auto;
        }
      }
    }
    @media screen and (min-width: 600px) {
      gap: 20px;
      overflow-x: hidden;
    }
  }
}

@keyframes stroke-draw {
  from{
    stroke: var(--primaria);
    stroke-dasharray: 1;
  }
  to{
    stroke: var(--primaria);
    transform:rotate(180deg);
    stroke-dasharray: 8;
  }
}
