.baixe-app{
  background-color: var(--background-cinza);
  display: flex;
  width: 109%;
  align-items: center;
  gap: 10px;
  padding: 20px 5px;
  h5{
    font-size: 19px;
  }
  span{
    color: var(--cinza-forte);
  }
  a{
    color: var(--link);
  }
  img{
    width: 100px;
  }
  .info{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  @media screen and (min-width: 600px) {
    display: none;
  }
}