ul#switch-idiomas {
    display: block;
    list-style: none;
    display: flex;
    align-items: center;
    @media(max-width: get-media(sm)) {
       justify-content: center;
       grid-column-end: 3;
    }
    padding: 0px;
    margin: 0px;
    li {
        padding: 0px;
        margin: 0px;
        padding-right: 10px;
        img {
            width: 30px;
            height: 30px;
        }
    }
}