//-----------------------------------------------------------------------------
// QUERIES 
//-----------------------------------------------------------------------------
$media: (
  default: 0,
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xx: 1400,
  xxl:1600
);

//-----------------------------------------------------------------------------
// FUNCTIONS
//-----------------------------------------------------------------------------
@function get-media($screenSize) {
  $size: map-get($media, $screenSize);
  @return #{$size}px;
}

//-----------------------------------------------------------------------------
// GERAL
//-----------------------------------------------------------------------------

:root {

  //card
  --card-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  --card-border: rgba(33, 55, 76, .1);
  // rgb(25 31 40 / 15%) 0px 6px 24px 0px


  //radius
  --radius: 5px;

  //spaces
  --padding: .5em;
  --margin: .5rem;

  //z-index
  --z-window: 8;
  --z-timer: 9;
  --z-nav: 1002;
  --z-header: 1001;

  --border-primary: 1px solid rgba(33, 55, 76, 0.5);  
  --color-border: rgba(153, 153, 153, 0.3);

  //grid media screen
  @each $k, $v in $media {
    --media-#{$k}: #{$v}px;
  }    

  //spaces
  --padding: .5em;
  --margin: .5rem;

  //accordion
  --summary-font-size: 0.875em;
}

a{
  text-decoration: none;
}

*{
  margin: 0;
}

body{
  margin: 0px;
  padding: 0px;
  border: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
  display: table;
  table-layout: fixed;
  &.body-background{
    background: var(--body-background);
  }
}

svg{
  fill: currentColor;
}

input, textarea, select {
  font-family: 'Rubik', sans-serif; 
}

strong{
  font-weight: 500;
}

.hidden {
  display: none;
}

@import "components/_header";
@import "components/_footer";
@import "components/_side-menu";
@import "components/_show";
@import "components/_layout";
@import "components/_info";
@import "components/_artistas";
@import "components/_buttons";
@import "components/_comprar-flutuante";
@import "components/_vitrine";
@import "components/_card";
@import "components/_destaques";
@import "components/_baixe-app";
@import "components/_busca";
@import "components/_tipo-evento";
@import "components/_localizacao";
@import "components/_idiomas";
@import "components/_chat";
@import "components/_modal";
@import "components/_pdv";
@import "components/_contador";
@import "components/_links";
@import "components/_banner";
@import "font-face.scss";