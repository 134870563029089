.swiper {
  max-width: 1400px;
  margin: 80px auto 0 auto;
  width: 95%;
  img {
    border-radius: 10px;
    text-align: center;
    width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    &.banner-mobile {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    margin-top: 150px;
    img {
      width: 100%;
    }
    &.banner-desktop {
      display: none;
    }
  }
}

.swiper.loading {
  height: 450px;
}

.react-multiple-carousel__arrow{
  z-index: 1;
}

.empty-banner{
  padding-top: 90px;
  @media screen and (max-width: 600px) {
    padding-top: 120px;
  }
}