.btn{
  &-comprar{
    background-color: var(--cor-evento);
    color: var(--white);
    padding: 25px 40px;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;  
    cursor: pointer;
    text-align: center;
    display: flex;
    position: relative;
    justify-content: center;
  }
  &-loja{
    background-color: var(--white);
    color: var(--cor-evento);
    border: 1px solid var(--cor-evento);
    padding: 15px 40px;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;  
    cursor: pointer;
    text-align: center;
    display: flex;
    position: relative;
    justify-content: center;
  }
  &-primaria{
    background-color: var(--primaria);
    color: var(--white);
    padding: 20px 60px;
    font-weight: bold;
    font-size: .9em;
    line-height: normal;
    border-radius: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;  
    cursor: pointer;
    text-align: center;
  }
  &-outline{
    border: 1px solid #CCC;
    color: #868585;
    width: 215px;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 20px 40px;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 1px;
    cursor: pointer;
  }
}