.lista{
  display: flex;
  margin: 25px auto;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 16px;
  flex-flow: row wrap;

  &::after {
    content: "";
    flex: auto;
  }


  &.artista{
    margin: 10px auto;
    justify-items: center;
    gap: 35px 32px;
  
    @media screen and (max-width: 1650px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 35px 32px;
    }
    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      margin: 0;
    }
  }
}

.card{
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  border:1px solid var(--card-border);
  width: 250px;
  min-height: 240px;
  max-width: 250px;
  align-content: flex-start;
  transition: transform .2s;
  

  @media screen and (max-width: 1440px) {
    max-width: 245px;
  }

  @media screen and (max-width: 1400px) {
    max-width: 240px;

  }

  @media screen and (max-width: 1380px) {
    max-width: none;
    width: 23%;
  }

  @media screen and (max-width: 1100px) {
    width: 30%;
  }

  @media screen and (max-width: 800px) {
    width: 45%;
  }

  @media screen and (max-width: 600px){
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, .05);
  }

 
  .imagem {
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    span{
      width: 100%;
    }
    img{
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-height: 150px;
      border-radius: 20px 20px 0 0;
    }
  }
      
    .info{
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 70%;
      .nome{
        color: var(--text-default);
        font-weight: 500;
        font-size: 18px;
        @media screen and (min-width: 600px) {
          letter-spacing: 0.01em;
        }
      }
      .cidade{
        color: var(--text-secundary);
        font-size: 14px;
        padding: 3px 0px 10px 0px;
      }
      .lugar{
        font-size: 13px;
        color: var(--text-gray);
        @media screen and (min-width: 600px) {
            display: none;
        }
      }
    }
}

.card:hover{
  // transform: scale(1.05);
  // box-shadow: 0 21px 32px -16px rgba(46, 55, 77 , 15%);
  text-decoration: none;
}

.card:focus{
  text-decoration: none;
}

@media screen and (max-width:600px) {
    #eventos{
      align-items: center;
      margin-top: -25px;
    }
    .lista{
      margin: 15px 2%;
      gap: 2px;
    }
    .card{
      border: none;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      min-width: 100%;
      min-height: auto;
      max-height: none;
      gap: 10px;
      border-bottom: 1px solid #E0E0E0;
      padding: 22px 0;
      border-radius: 0;
      &:last-of-type{
        border-bottom: none;
      }
      .imagem{
        order: 2;
        width: 205px;
        height: 100px;      
        margin-bottom: 0;
        img{
          border-radius: 20px;
          object-fit: cover;
        }
      }
      .data{
        order: 1;
      }
      .info{
        order: 3;
        width: 100%;
        gap: 2px;
        letter-spacing: 0.02em;
        .nome{
            font-size: 20px;
        }
      }
    }
    .card:hover{
        box-shadow: none;
    }
}