//AÇÕES DE QUERIES DEPENDENDO DA SITUAÇÃO DO EVENTO 
//ADICIONAR AS CLASSES ¨modelo-default¨ , ¨modelo-cores¨ e ¨modelo-banner¨ no body


//-----------------------------------------------------------------------------
// PADRÃO MODELO SIMPLES (SOMENTE A COR DO EVENTO E A FOTO)
//-----------------------------------------------------------------------------

.modelo-evento-simples{
  .navbar{
    max-width: none;
    .logo{
      padding-left: 20px;
    }
  }

  .header-bg{
    &::after{
      position: absolute;
      content:"";
      height:100%;
      width:100%;
      top:0;
      left:0;
      background: linear-gradient(0deg, rgba(42, 42, 42, 0.80) 0%, rgba(42, 42, 42, 0.50) 100%);
      // filter: blur(12px);
      @media screen and (max-width: 600px) {
        height: 300px;
      }
    }
    &::before{
      content: "";
      position: absolute;
      width : 100%;
      height: 100%;
      background: inherit;
      z-index: -1;
      margin: -5px;
  
      filter        : blur(5px);
      -moz-filter   : blur(5px);
      -webkit-filter: blur(5px);
      -o-filter     : blur(5px);
  
      @media screen and (max-width: 600px) {
        height: 300px;
      }
      @media screen and (min-width: 1920px) {
        width: 98%;
      }
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: var(--background-simples) ;
    @media screen and (min-width: 600px) {
      height: auto;
      min-height: 250px;           
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo{
        svg{
          color: var(--cor-evento-secundaria);
        }
        a img{
          color: var(--cor-evento-secundaria);
        }
      }   
      .acesso{
        .login{
          color: var(--cor-evento-secundaria);
        }
        .cadastrar{
          color: var(--cor-evento);
          background-color: var(--cor-evento-secundaria);
        }
      }

    }
    @media (min-width: 601px) and (max-width: 900px){
      .acesso .login{
        display: none;
      }
    }
  }

  &#scroll-banner{
    .header-bg{
      min-height: 0px;
    }
  }

  .container-evento{
    @media screen and (min-width: 1400px) {
      aside .evento-menu{
        margin-top: -84px;
        z-index: 200;
      }
    }
  }

  //tratando banner simples em resoluções widescreen
  @media screen and (min-width: 1920px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: var(--background-simples);
    &::after{
      position: absolute;
      content:"";
      height:250px;
      width:100%;
      top:0;
      left:0;
      background: linear-gradient(0deg, rgba(42, 42, 42, 0.60) 0%, rgba(42, 42, 42, 0.60) 100%);
    }
    &::before{
      content: "";
      position: absolute;
      width : 100%;
      height: 250px;
      background: inherit;
      z-index: -1;
      filter        : blur(5px);
      -moz-filter   : blur(5px);
      -webkit-filter: blur(5px);
      -o-filter     : blur(5px);
      transition        : all 2s linear;
      -moz-transition   : all 2s linear;
      -webkit-transition: all 2s linear;
      -o-transition     : all 2s linear;
    }
  }
}

//-----------------------------------------------------------------------------
// PADRÃO MODELO COMPLETO (COM O BANNER E FOTO DO EVENTO)
//-----------------------------------------------------------------------------

.modelo-evento-completo{
  .navbar{
    max-width: none;
    .logo{
      padding-left: 20px;
    }
  }

  .header-bg{
    &::after{
      position: absolute;
      content:"";
      height:100%;
      width:100%;
      top:0;
      left:0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 64.58%);
      @media screen and (max-width: 600px) {
        height: 300px;
      }
      &.artista{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 64.58%);
        @media screen and (max-width:600px) {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 64.58%);
        }
      }
    }
    &.artista{
      &::after{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 92%);
      }
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: var(--background-banner) ;
    height: 300px;
    @media screen and (min-width: 600px) {
      height: 300px;            
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: var(--background-banner) ;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo{
        svg{
          color: var(--cor-evento-secundaria);
        }
        a img{
          color: var(--cor-evento-secundaria);
        }
      }   
      .acesso{
        .login{
          color: var(--cor-evento-secundaria);
        }
        .cadastrar{
          color: var(--cor-evento);
          background-color: var(--cor-evento-secundaria);
        }
      }

    }
    @media screen and (min-width:1300px) and (max-width:1600px) {
      height: 275px;
    }
    
  }

  .container-evento{
    @media screen and (min-width: 1400px) {
      aside .evento-menu{
        margin-top: -84px;
        z-index: 200;
      }
    }
  }

  //tratando banner completo em resoluções widescreen
  @media screen and (min-width: 1920px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: var(--background-banner);
    &::after{
      position: absolute;
      content:"";
      height:300px;
      width:100%;
      top:0;
      left:0;
      background: linear-gradient(0deg, rgba(42, 42, 42, 0.60) 0%, rgba(42, 42, 42, 0.60) 100%);
    }
    &::before{
      content: "";
      position: absolute;
      width : 100%;
      height: 300px;
      background: inherit;
      z-index: -1;
      filter        : blur(5px);
      -moz-filter   : blur(5px);
      -webkit-filter: blur(5px);
      -o-filter     : blur(5px);
      transition        : all 2s linear;
      -moz-transition   : all 2s linear;
      -webkit-transition: all 2s linear;
      -o-transition     : all 2s linear;
    }
  }
  @media screen and (max-width: 600px){
    height: 230px;
  }
}

//-----------------------------------------------------------------------------
// SCSS GERAL, SEM INTERFERIR NOS MODELOS
//-----------------------------------------------------------------------------

.header-bg{
  position: sticky;
  align-self: start;
  top: -1px;
  // background-color: var(--white);
  z-index: 2;
  max-width: 1920px;
  margin: 0 auto;
  .titulo{
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 14px;
    // max-width: 1920px;
    flex-direction: column;
    .nome{
      padding-left: 280px;
      color: var(--white);
      margin-bottom: 0px;
      // max-width: 660px;
      align-self: flex-start;
      margin-top: 10px;
      position: relative;
      h1{
        font-weight: 500;
        font-size: 2.3em;
      }
      h4{
        font-weight: 400;
      }

      @media screen and (min-width:1920px) {
        
      }
    }
    .helpers{
      display: flex;
      align-self: flex-start;
      width: 100%;
      gap: 10px;
      justify-content: space-between;
    }
    .sessoes{
      padding-left: 280px;
    }
  }
  .comprar{
    margin-right: 60px;
    display: flex;
    align-items: center;
    gap: 20px;
    .btn-comprar{
      margin-bottom: -40px;
      border-color: var(--cor-evento);
      @media screen and (max-width: 830px) {
        font-size: 14px;
        padding: 25px 30px;
      }
      @media screen and (min-width: 1200px) {
        min-width: 180px;
      }
    }
    .btn-loja {
      margin-bottom: -40px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-color: var(--cor-evento);
      @media screen and (max-width: 830px) {
        font-size: 14px;
        padding: 25px 30px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    height: 230px;
  }
}

#scroll-banner{
  .titulo{
    display: none;
  }
  .content{
    padding-top: 50px;
  }
}

.sessoes{
  display: flex;
  margin-top: 5px;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 1000px;
  span{
    padding: 5px 10px;
    background-color: var(--cor-evento);
    color: var(--white);
    border-radius: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    @media screen and (max-width: 600px) {
      gap: 3px;
      // width: 100%;
      color: var(--cor-evento);
      background-color: #FFFFFF;
      border: 2px solid var(--cor-evento);
      // justify-content: space-between;
      justify-content: center;
      gap: 8px 4px;
    }
    &.disable{
      background-color: #a8a8a8;
      color: #fff;
      border: 1px solid #a8a8a8;
    }
  }
  svg{
    width: 14px;
    height: 14px;
  }
  @media screen and (max-width: 600px) {
    gap: 8px 4px;
    margin-bottom: 11px;
  }
}

.container-evento{
  padding: 0px 2% 45px 2%;
  max-width: 1920px;
  margin: 0 auto;
  aside{ 
    position: sticky;
    align-self: start;
    top: 128px;
    z-index: 10;
    .evento-menu{
      margin-top: -115px;
    }
    &.sem-foto{
      margin-top: 120px;
      @media screen and (max-width: 600px) {
        margin-top: 225px;
      }
    }
  }
  //MOBILE
  @media screen and (max-width: 600px){
    aside .evento-menu{
      margin-top: 0px;
    }
  }
  @media screen and (min-width: 600px) {
    .layout .content{
      margin-bottom: 100px;
  }
  }
}


@media screen and (min-width:600px) { 
  #scroll-banner{
    transition: max-height 0.15s ease-out;
    .header-bg{
      position: fixed;
      width: 100%;
      height: 73px;
      z-index: 10;
      max-width: none;
      .navbar{
        @media (min-width: 1550px) {
          .logo{
            padding-left: 300px;
          }
        }
        @media (min-width: 1600px) {
          .logo{
            padding-left: 295px;
          }
        }
        @media (max-width: 1549px) {
          .logo{
            padding-left: 280px;
          }
        }
        @media (min-width: 1761px){
          .logo{
            padding-left: 300px;
          }
        }
        @media (min-width: 2000px){
          .logo{
            padding-left: 17%;
          }
        }
        @media (min-width: 2500px){
          .logo{
            padding-left: 23%;
          }
        }
      }
      &::after{
        position: absolute;
        content:"";
        height:100%;
        width:100%;
        top:0;
        left:0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 64.58%);
      }
    }
    .container-evento{
        .aside{
            position: sticky;
            align-self: start;
            top: 113px;
            z-index: 100;
        }
    }
  }   
}

//-----------------------------------------------------------------------------
// PADRÃO MOBILE (A VERSÃO MOBILE NÃO ALTERA COM O TIPO DE EVENTO)
//-----------------------------------------------------------------------------

@media screen and (max-width: 600px){
  .container-evento{
    .layout{
      gap: 15px;
      z-index: 10;
      margin-top: -125px;
    }
    aside {
      margin: 0 auto;
      position: unset;
      z-index: 0;
      .evento-menu{
        align-items: center;
        gap: 10px;
        .titulo{
          align-items: center;
          h1{
            font-size: 27px;
          }
          .data{
            .dia{
            font-size: 40px;
            }
            .mes{
            font-size: 22px;
            }
          }
        }
        a img{
          width: 325px;
          height: 325px;
        }
      }
      .side-menu{
        display: none;
      }
      .titulo{
        display: flex;
      }
      .comprar{
          background-color: var(--primaria);
          padding: 20px 36%;
          border-radius: 5px;
          margin-top: 20px;
          a{
              color: var(--white);
              font-weight: 600;
              letter-spacing: 0.04em;
              font-size: 18px;
          }
      }
    }
    #ingressos{
        display: none;
    }
    .info{
      .info-header{
          // display: none;
          border: none;
          box-shadow: none;
          padding: 0px;
          margin-bottom: 24px;
      }
      .info-body{
          border: none;
          box-shadow: none;
          padding: 0px;
          font-size: 1em;
          .subtitle{
            font-size: 1.45em;
          }

          .atracoes{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px 10px;
            .card{
              min-height:auto;
              min-width: 100%;
              border: 1px solid #E0E0E0;
              border-radius: 15px;
              justify-content: space-between;
            }
            .imagem{
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
            .artista{
              margin-left: 10px;
            }
            .imagem img{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
      }
    }
    .block{
      padding: 0px 3%;
    }
    .content{
      margin-top: 0px;
      margin-bottom: 100px;
    }
  }
  .logo{
    svg{
      color: var(--white);
    }
  }
  .header-bg{
    // background-image: none !important;
    .navbar{
      height: 200px;
      align-items: flex-start;
      padding-top: 23px;
      position: inherit;
      .acesso{
        margin-top: 0px;
        svg{
          color: var(--cor-evento);
          width: 35px;
          height: 35px;
        }
      }
      .logo{
        padding-top: 3px;
      }
    }
    .titulo{
      display: none;
    }
  }

  #info-mobile{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 3%;
    order: -2;
    h2{
      color: var(--text-dark);
      font-size: 32px;
    }
    h4{
      color: var(--text-dark);
      font-weight: 500;
      margin: 8px 0px;
    }
  }
}

@media screen and (min-width: 601px) {
  .container-evento{
    aside{
      .data{
        display: none;
      }
      .comprar{
        display: none;
      }
    }
  }
  #info-mobile{
    display: none;
  }
}


.cor-logo{
  path{
    fill: red;
  }
}