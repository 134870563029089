.pdv{
  &-lista{
    display: flex;
    flex-direction: column;
  }
  &-item{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    gap: 10%;
    padding: 12px 0px;
    justify-content: space-between;
    .info{
      display: flex;
      flex-direction: column;
      b{
        font-size: 18px;
      }
      span{
        font-size: 14px;
      }
    }
    .icon{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--cor-evento) !important;
      gap: 2px;
      span{
        text-align: center;
        font-size: 10px;
        white-space: nowrap;
        font-weight: bold;
        text-transform: uppercase;
      }
      svg{
        width: 20px;
        height: 30px;
      }
    }

  }
}