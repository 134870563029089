.link-baladapp {
  max-width: 680px;
  margin: 10px 30px;

  @media screen and (min-width: 600px) {
    margin: 0 auto;
  }

  section,
  .header {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
    margin-bottom: 18px;
  }

  .header {
    margin: 30px 10px 25px 10px;
    gap: 7px;

    img {
      align-self: center;
      width: 100px;
      height: 100px;
    }

    span:first-of-type {
      font-size: 19px;
      font-weight: 500;
    }

    span,
    section h2 {
      color: var(--text-default);
    }
  }

  .card-link {
    border: 1px solid #dadada;
    background-color: #ffffff;
    display: block;
    min-height: fit-content;
    color: var(--text-default);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px 0px;
    border-radius: 8px;
    text-decoration: none;
    padding: 20px;
    transform: scale(1);

    &:hover {
      box-shadow: rgb(0, 0, 0, 0.24) 0px 4px 12px 0px;
      transform: scale(1.01);
    }

    &.primary {
      background-color: var(--primaria);
      border-color: var(--primaria);
      font-weight: 500;
      color: #ffffff;
    }
  }

  .redes {
    padding: 10px 0;
  }

  h2{
    color: var(--text-default);
  }

}
